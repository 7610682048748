* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none; }
  *:before, *:after {
    box-sizing: border-box; }

$mq-mobile-landscape	: 480px !default;
@mixin mobile-only {
	@media (max-width : $mq-mobile-landscape) {
		@content;
	}
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F5FEFF;

  padding: 40px;
  @include mobile-only(){
    padding: 16px;
  }

  @media (max-width : 320px) {
    padding: 4px;
	}
}

a:hover{
  opacity: 0.7;
}

html, body {
  width: 100%;
  height: 100%;
}

h1 {
  font-weight: 800;
  font-size: 50px;
  line-height: 60px;
  margin-bottom: 20px;

  @include mobile-only(){
    font-size: 36px;
    line-height: 110%;
  }
}

p {
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: rgba(black, 0.6);
  margin: 7.5px 0;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 30px;

  @include mobile-only(){
    margin-bottom: 12px;
    .logo {
      height: 50px;
      width: auto;
    }
  }

  @media (max-width : 320px) {
		.logo {
      height: 40px;
    }
	}

  .social-buttons {
    a {
      opacity: 0.5;
    }
    a:hover {
      opacity: 1;
    }
  }
}



.phone {
  position: relative;
  margin-right: 60px;

  .shadow {
    position: absolute;
    left: -70px;
    top: -60px;
    z-index: 0;
  }

  .screen, video {
    position: absolute;
    width: 290px;
    height: auto;
    z-index: 1;
    left: 21px;
    top: 13px;
  }

  .frame {
    position: relative;
    z-index: 2;
  }
}

.main {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mobile-only(){
    display: block;
  }
}

.content {
  width: 517px;

  video {
    display: none;
  }

  @include mobile-only(){
    width: 100%;
    text-align: center;
    padding-bottom: 32px;

    video {
      display: block;
      width: calc(100% + 32px);
      height: auto;
      margin-bottom: 24px;
      margin-left: -16px;
      margin-right: -16px;
    }
  }

  @media (max-width : 320px) {
    padding-bottom: 16px;
		video {
      width: calc(100% + 8px);
      margin-left: -4px;
      margin-right: -4px;
    }
	}
}

.buttons {
  display: flex;
  align-items: center;
  margin-top: 80px;

  @include mobile-only(){
    display: block;
    margin-top: 30px;
  }

  .qr-code {
    text-align: center;
    img {
      width: 124px;
      height: auto;
      display: block;
    }
    span {
      display: block;
      font-size: 14px;
      line-height: 17px;
      margin-top: 8px;
    }
  }

  .separator {
    margin: 0 20px;
  }
}

@include mobile-only(){
  .preview, .qr-code, .separator {
    display: none;
  }
}